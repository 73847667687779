.font{
    font-size: 8px;
}

.div{
    margin-left: 8px;
    margin-right: 8px;
}
.downloadStyle{
    text-decoration: underline;
    margin-left: 20px;
    margin-right: 40px;
    color: #54BCBD;
}
.publicStyle{
    display: flex;
    align-items: center;
    margin-bottom: 36px;
}
.pagination{
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
}