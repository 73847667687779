.container{
    background-color: #fafafa;
    /* height: 100vh; */
    /* min-height: 100%; */
}
.main{
    /* margin-left: 20px;
    margin-right: 20px; */
    padding: 10px 20px 80px;
    height: 100vh;
}
 .content{
    border: 1px solid #C0C0C0;
    background-color: white;
    margin-bottom: 50px;
    /* 88vh */
    /* height: 100%; */
}

.contentStyle{
    margin-left: 40px;
    margin-top: 40px;
}
