.itemLeft{
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.itemRight{
    text-align: center;
    padding-top: 9px;
    padding-bottom: 9px
}
.downloadStyle{
    text-decoration: underline;
    margin-left:5px;
    margin-right: 60px;
    color: #54BCBD;
    cursor: pointer;
}
.title{
    display: flex;
    justify-content: space-between;
    margin-right: 60px;
}
.head{
    display: flex;
    justify-content: space-around;
    background-color: #fafafa;
    border: 1px solid #eee;;
    text-align: center;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: 16px;
}
.bottom{
    display: flex;
    justify-content: space-around;
}

.formItem :global .ant-row {
    justify-content: space-around;
}