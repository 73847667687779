.top{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
}
.center{
    display: flex;
    align-items: center;
    margin-left: 112px;
}
.bottom{
    display: flex;
    align-items: baseline;
    margin-bottom: 400px;
    margin-top: 50px;
}