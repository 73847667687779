.reprintTop ,.reprintMiddle{
    border: 1px solid #C0C0C0;
    background-color: white;
    width: 100%;
    padding: 20px;
}
.ant-row {
    width: 100%;
}
.form{
    padding: 10px;
    /* font-weight: bold; */
    display: flex;
}
.time{
    padding: 10px;
    /* margin-top: 15px; */
    margin-bottom: 15px;
    /* font-weight: bold; */
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.timeIcon{
    color: blue;
    margin-right: 5px;
}
.reprintMiddle{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
}
.reprintBottom{
    border-left: 1px solid #C0C0C0;
    border-right: 1px solid #C0C0C0;
    border-bottom: 1px solid #C0C0C0;
    background-color: white;
    width: 100%;
    padding: 20px 20px 0px 20px;
}
.editStyle{
    display: flex;
    justify-content: center;
    align-items: center;
}
.printNumStyle{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.font{
    font-size: 12px;
}