.left{
    text-align: right;
    width: 112px;
    margin-right: 20px;
}
.publicStyle{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}
.breedStyle{
    display: flex;
    align-items: baseline;
}
.a{
    display: flex;
    align-items: center;
    /* margin-bottom: 40px; */
}
.middle{
    text-align: right;
    width: 80px;
    margin-right: 30px;
}
.font{
    margin-left: 10px;
    margin-right: 10px;
    width: 90px;
    text-align: right;
    display:inline-block;
}
.bottom{
    text-align: right;
    margin-right: 80px;
    margin-top: 40px;
    margin-bottom: 40px;
}
.content{
    border: 1px solid #C0C0C0;
    background-color: white;
    margin-bottom: 50px;
    /* 88vh */
    /* height: 100%; */
}



.basicTop{
    border: 1px solid #C0C0C0;
    padding-left: 15px;
    margin-right: 40px;
    padding-bottom:  30px;
    padding-top: 30px;
}
.public{
    margin-right: 30px;
}

.ruleTop{
    border-top: 1px solid #C0C0C0;
    border-left: 1px solid #C0C0C0;
    border-right: 1px solid #C0C0C0;
}
.fieldStyle{
    padding-left: 10px;
    padding-right: 10px;
}

.siteTagPlus {
    background: #fff;
    border-style: dashed;
  }
.editTag {
user-select: none;
}
.tagInput {
width: 78px;
margin-right: 8px;
vertical-align: top;
}
.u-minPrint-message {
    position: 'absolute';
    top: '0px';
    right: '50%';
    transform: translateX(-50%);
}

