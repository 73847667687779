.content{
    border: 1px solid #C0C0C0;
    background-color: white;
    margin-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
}

/*内容头部操作区*/
.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
}

.popconfirm {
    text-align: center;
}
.popconfirm span {
    color: #B9B9B9;
    font-size: 12px;
}

.modalConfirm {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.editStyle{
    display: flex;
    justify-content: center;
    align-items: center;
}

.printNumStyle{
    display: flex;
    justify-content: space-around;
    align-items: center;
}