.contentStyle{
    /* margin-top: 40px; */
    display: flex;
    align-items: baseline;
    margin-top: 20px;
    margin-bottom: 40px;
}
.spanStyle{
    margin-right: 30px;
}
.downloadStyle{
    text-decoration: underline;
    margin-left: 20px;
    margin-right: 40px;
    color: #54BCBD;
}
.timeIcon{
    display: flex; 
    align-items: baseline;
    margin-left: 18px;
    margin-top: 10px;
    color:#A9A9A9
}